import classNames from "classnames";
import { ImageProps } from "next/image";
import Link from "next/link";
import { ButtonHTMLAttributes, PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";
import { PropsWithClassAndStyle } from "utils/props";
import { PropsWithTestAnchor } from "utils/test";


const buttonVariants = {
  'default': classNames('bg-secondary text-white'),
  'default-outline': classNames('border border-secondary text-secondary'),
  'default-outline-hover': classNames('border border-secondary text-secondary hover:bg-secondary hover:text-white duration-300'),
  'light': classNames('bg-light-grey text-black'),
  'white': classNames('bg-white'),
  'transparent': classNames('bg-transparent'),
}
type ButtonProps = PropsWithTestAnchor<Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & PropsWithChildren<PropsWithClassAndStyle<{
  colorscheme?: keyof typeof buttonVariants
} & {
  onClick?: () => any
}>>>
type ImageButtonProps = Omit<ButtonProps, 'colorscheme'> & { src: string, width: number | string; height: number | string }

export function ImageButton({ src, style, width, height, type, ...all }: ImageButtonProps) {
  return <Button {...all} type={type} style={{ padding: 0, ...style }} colorscheme="transparent">
    <img src={src} width={width} height={height} />
  </Button>
}

const buttonBaseCx = classNames('rounded-md', 'py-[7px]', 'focus-outline')

export default function Button({ children, onClick, className, style, colorscheme = 'default', ...html }: ButtonProps) {
  // TODO: refactor out Button v1 to replace it with padding and stuff of v2.
  return <button onClick={onClick} className={classNames(className, buttonVariants[colorscheme], 'px-0.5', buttonBaseCx)} style={style} {...html}>
    {children}
  </button>
}
export function ButtonV2({ children, onClick, className, style, colorscheme = 'default', ...html }: ButtonProps) {
  return <button onClick={onClick} className={twMerge(classNames(buttonBaseCx, 'px-1', 'disabled:cursor-no-drop disabled:opacity-50', buttonVariants[colorscheme], className))} style={style} {...html}>
    {children}
  </button >
}



type LinkButtonProps = Omit<ButtonProps, 'onClick'> & { href: string }

export function LinkButton({ children, href, className, style, colorscheme = 'default' }: LinkButtonProps) {
  return <Link href={href} passHref>
    <a className={classNames(className, buttonVariants[colorscheme], buttonBaseCx, 'px-1', '!no-underline', 'flex w-fit' /* since it is not a button */)} style={style}>
      {children}
    </a>
  </Link>
}
