import getConfig from 'next/config'
import url from 'url'


export function createAuthorizedFetch(token?: string | null): typeof fetch {
  if (!token) return fetch
  return (input, init) => {
    init ??= {}
    init!.headers = { ...init.headers, 'Authorization': token }

    return fetch(input, init)
  }

}

export function authorizedRequest(token: string, init: RequestInit = {}): RequestInit | undefined {
  init.headers ??= {}
  init.headers = { ...init.headers, 'Authorization': token }

  return init
}

