import { USER_ENDPOINT, USER_NEWLY_REGISTERED_ENDPOINT } from "api/endpoints";
import { useAPIFetch } from "hooks/fetch";
import { useUser } from "hooks/user";
import { GetStaticPropsContext } from "next";
import React, { useContext, useMemo } from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { ButtonV2 } from "./Button";
import { NamedModalWindow } from "./ModalWindow";



export function NewlyRegisteredPopup() {
  const [user] = useUser()
  const [open, setOpen] = useState(false)
  const apiFetch = useAPIFetch()
  useEffect(() => {
    setOpen(true)
  }, [])
  if (!user || !user.newly_registered) {
    return <></>
  }



  const handleClose = async () => {
    if (!user) return
    await apiFetch(USER_NEWLY_REGISTERED_ENDPOINT(user.id!), { method: 'DELETE' })
  }

  return <NamedModalWindow name="Дополнительная информация" contentLabel="newlyregistered" open={open} close={() => {
    handleClose()
    setOpen(false)
  }} className="py-2 !px-2 w-25 overflow-y-auto items-start" titleClassName="text-[1.5rem] text-semibold" closable>
    Укажите, пожалуйста, дополнительную информацию о себе
    <div className="flex flex-row gap-1">
      <ButtonV2 colorscheme="default-outline" onClick={() => {
        handleClose()
        setOpen(false)
      }}>Закрыть</ButtonV2>
      <ButtonV2 onClick={() => {
        handleClose()
        setOpen(false)
        window.open('https://forms.yandex.ru/cloud/66cf0b5bf47e733a06a05888?answer_login=' + user.nickname, '_blank')
      }}>Заполнить форму</ButtonV2>
    </div>
  </NamedModalWindow>
}
