import { useUser } from "hooks/user";
import { GetStaticPropsContext } from "next";
import React, { useContext, useMemo } from "react";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { NamedModalWindow } from "./ModalWindow";


type ChangelogEntry = {
  date: Date
  contents: string
}

export const ChangelogModalStateContext = React.createContext<[boolean, (v: boolean) => void]>([false, () => { }])

export function ChangelogModal() {
  const [user] = useUser()
  const { data: entry, isLoading, isError } = useQuery('changelog', {
    queryFn: async () => {
      const resp = await fetch('/api/changelog')
      if (!resp.ok) throw new Error('Could not fetch changelog data')

      const { date, contents } = await resp.json() as ChangelogEntry
      return { date: new Date(date), contents }
    }
  })
  const [accepted, setAccepted] = useState<Date | null | undefined>(undefined)
  const [open, setOpen] = useContext(ChangelogModalStateContext)
  useEffect(() => {
    const item = localStorage.getItem('accept-changelog')
    setAccepted(item ? new Date(item) : null)
  }, [])

  // useEffect(() => {
  //   console.log('test', user)
  // }, [user])

  // console.log('changelog', isLoading, isError, accepted === undefined, user, user ? new Date(user?.created_at) : undefined, entry ? entry.date : undefined)

  if (isLoading || isError || !user) {
    console.log('bruh')
    return <></>
  }

  return <NamedModalWindow name="Изменения" contentLabel="changelog" open={open || ((!accepted || accepted.getTime() !== entry!.date.getTime()) && new Date(user?.created_at).getTime() < entry!.date.getTime() && !user.newly_registered)} close={() => {
    setAccepted(entry!.date)
    setOpen(false)
    localStorage.setItem('accept-changelog', entry!.date.toISOString())
  }} className="py-2 !px-2 w-25 overflow-y-auto items-start" titleClassName="text-[1.5rem] text-semibold" closable>
    {/* <div className="prose w-full mt-1" dangerouslySetInnerHTML={{ __html: entry!.contents }} /> */}
    <div className="prose w-full mt-1" dangerouslySetInnerHTML={{ __html: entry!.contents }} />
  </NamedModalWindow>
}
